import { DiscoChip, DiscoChipColor } from "@disco-ui"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import { TestIDProps } from "@utils/typeUtils"

type Props = TestIDProps & {
  status: boolean | null
  isTableRow?: boolean
}

function AssignmentSubmissionStatusChip(props: Props) {
  const { status, testid = "AssignmentSubmissionStatusChip", isTableRow } = props
  const { passFailAssignments } = useFeatureFlags()
  const { color, label } = getChipProps()

  if (!passFailAssignments) return null

  return (
    <DiscoChip
      color={color}
      label={label}
      data-testid={`${testid}.status`}
      marginLeft={isTableRow ? 0 : 1}
      marginRight={isTableRow ? 0 : 1}
    />
  )

  function getChipProps(): { color: DiscoChipColor; label: string } {
    switch (status) {
      case true:
        return {
          color: "green",
          label: "Passed",
        }

      case false:
        return {
          color: "red",
          label: "Failed",
        }

      default:
        return {
          color: "yellow",
          label: "Pending Review",
        }
    }
  }
}

export default AssignmentSubmissionStatusChip
